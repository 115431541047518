<template>
  <div>
    <profile-header text="Notifications" />

    <v-list two-line >
      <v-list-item-group>
        <template v-for="(item, i) in notifications">
          <v-list-item :key="`notification-${i}`">
            <v-list-item-icon>
              <v-icon>far fa-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit,
                commodi?
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-divider
            v-if="i + 1 < notifications.length"
            :key="`notification-divider-${i}`"
          >
          </v-divider>
        </template>
        
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import ProfileHeader from "./ProfileHeader.vue";
export default {
  components: { ProfileHeader },
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {
    const length = Math.floor(Math.random() * 30);
    for (let i = 0; i < length; i++) {
      this.notifications.push({
        title: "Notification Alert",
      });
    }
  },
};
</script>