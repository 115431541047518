<template>
  <header class="profile-header">
    <v-row dense no-gutters>
      <v-col>
        <h1>{{ text }}</h1>
      </v-col>
      <v-spacer />

      <v-col>
        <slot>
          <v-tabs v-if="tabs" v-model="selected" right @change="tabSelected">
            <v-tab class="tab" :key="i" v-for="(tab, i) in tabs">
              {{ tab }}
            </v-tab>
          </v-tabs>
        </slot>
      </v-col>
    </v-row>

    <v-divider />
  </header>
</template>

<script>
export default {
  props: {
    text: { type: String, requried: true },
    tabs: {
      type: Array,
    },
  },
  data() {
    return { selected: null };
  },
  methods: {
    tabSelected(index) {
      this.$emit("tab-selected", this.tabs[index]);
    },
  },
};
</script>


<style lang="scss" scoped>
.profile-header {
  margin-bottom: 1rem;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-end;
  align-items: center;

  .tab {
    margin: 0.5rem;
  }
}
</style>